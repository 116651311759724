import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Drupal services`}</h1>
    <h2>{`Why you need Drupal Services`}</h2>
    <p>{`I know you're a busy person and your website may not be receiving the attention, nurturing and maintenance it needs. I can help. I maintain and support Drupal websites for busy business owners.`}</p>
    <p>{`Let me provide your website with things such as proactive maintenance, bug fixes, security updates, and the extra special attention it so desperately needs. The reality is, if you want your website to work, it must be fresh, it must be cared for, it must be supported.`}</p>
    <h2>{`Services include:`}</h2>
    <h3>{`Drupal Repairs`}</h3>
    <p>{`I take care of small fixes and updates, jobs that are too small to interest web agencies but too technical for you. Random error messages? Some Views giving you trouble? Doesn't look good in IE? I'll make any update.`}</p>
    <h3>{`Drupal Maintenance`}</h3>
    <ul>
    <li>Installing themes and modules</li>
    <li>Perform security updates for both Drupal Core and Contributed modules</li>
    <li>Scan for bad links, malware, and slow page speeds</li>
    </ul>
    <h3>{`Drupal Migration Services`}</h3>
    <p>{`Are you running an old version of Drupal? Say Drupal 6/7? I offer migration services to the latest Drupal 8 version.`}</p>
    <h3>{`Drupal E-commerce Services`}</h3>
    <p>{`Interested in selling online? I use the robust Drupal commerce ecosystem to build you an E-commerce platform that converts into sales for you.`}</p>
    <h3>{`3rd party API integration`}</h3>
    <p>{`I help you integrate 3rd party software that you may need to use with your existing Drupal website.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      